import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './GamePage.module.css'

function GamePage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [game, setGame] = useState({slangCards:[]});
    const { gamePath } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const gameResponse = await fetch(`${apiUrl}game/byPath/${gamePath}`);
            const game = await gameResponse.json();
            setGame(game);
        };

        fetchData();
    }, [apiUrl, gamePath]);

    return (
        <div className={styles.container}>
            <h1>Game {game.path}</h1>
            <h3>Situation (Sling): {game.situation}</h3>
            <p>Now select a card that you feel best works with the situation </p>
            <div>
                {game.slangCards.map((card) => (
                    <div className={styles.card} key={card.id}>
                        <img src={"/img/" + card.allPicturePath} alt={card.slang}/>
                        {/*<div>*/}
                        {/*    <div className={styles.slang}> {card.slang}</div>*/}
                        {/*    <div className={styles.explanation}> {card.explanation}</div>*/}
                        {/*    <div  className={styles.example}> {card.example}</div>*/}
                        {/*</div>*/}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GamePage;