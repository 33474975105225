import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import IndexPage from "./pages/IndexPage";
import GamePage from "./pages/GamePage";

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<IndexPage/>} />
                <Route path="/game/:gamePath" exact element={<GamePage/>} />
            </Routes>
        </Router>
    );
}

