import React from 'react';
import styles from './IndexPage.module.css';
import GenerateGameButton from "../components/GenerateGameButton/GenerateGameButton";

function IndexPage() {

    return (
        <div className={styles.container}>
            <h1>Sling That Slang</h1>
            <p>Is a game that helps to learn American slang in a fun way.</p>
            <GenerateGameButton />
        </div>
    );
}

export default IndexPage;