import React, { useState } from 'react';
import styles from './GenerateGameButton.module.css';


const GenerateGameButton = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const host = process.env.REACT_APP_HOST;

    const [game, setGame] = useState(null);
    const [copied, setCopied] = useState(false);

    const handleGenerateGame = async () => {

        try {
            // Make the request to generate a new game
            const response = await fetch(`${apiUrl}game`, {
                method: 'POST',
            });
            const game = await response.json();

            // Update state with the generated game name
            setGame(game);
        } catch (error) {
            console.error('Error generating a new game:', error);
        }
    };

    const handleCopyLink = () => {
        // Create a temporary input element to copy the link to the clipboard
        const tempInput = document.createElement('input');
        tempInput.value = `${host}/game/${game.path}`;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);

        setCopied(true);
    };

    return (
        <div className={styles.container}>
            {!game ? (
                <button className={styles.generate} onClick={handleGenerateGame}>Generate new game!</button>
            ) : (
                <div>
                    <h3>{`Game ${game.path} is created !`}</h3>
                    <div><a href={`/game/${game.path}`}>Open Game</a></div>
                    <button className={styles.copy} onClick={handleCopyLink}>{copied ? 'Link Copied!' : 'Copy Link'}</button>
                </div>
            )}
        </div>
    );
};

export default GenerateGameButton;
